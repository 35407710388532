
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchPageRT () {
    return _createElement('div', { 'className': 'clear-self cm_SearchPage' }, [_createElement('div', {
            'className': 'cm_FacetPanel',
            'key': '410'
        }, [this.FilterChips(function () {
                return _createElement('div', { 'className': 'cm_FilterChips' }, [this.filterChips(function () {
                        function repeatChips1(chips, chipsIndex) {
                            return [chips(function () {
                                    function repeatI1_reviewChip(i, iIndex) {
                                        return _createElement('span', {
                                            'key': `${ this.field }|${ i }`,
                                            'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                        });
                                    }
                                    function scopeInchMm1_rangeChip() {
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '365' }, this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '491' }, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '591' }, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                                'className': 'cm_icon cm_icon-times',
                                                'height': '20px',
                                                'role': 'img',
                                                'viewBox': '0 0 22 22',
                                                'key': '7210'
                                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                    }
                                    return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '2570'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                        'a',
                                        {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        },
                                        _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI1_reviewChip.bind(this)),
                                        this.value !== '5' ? _createElement('span', { 'key': '391' }, ' and up') : null
                                    ]), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '4900'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                            'className': 'facettitle',
                            'tabIndex': '0'
                        }, 'Current search:'), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                            'div',
                            {
                                'role': 'list',
                                'className': 'cmRepeater_chips'
                            },
                            _map(this.chips, repeatChips1.bind(this))
                        ]), _createElement('div', {
                            'className': 'cm_btn button button--secondary cm_filter-chips_button',
                            'data-cm-role': 'start-over'
                        }, '\n        Start over\n      '))));
                    }, {
                        widgetName: 'filter-chips',
                        items: undefined
                    })]);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.FacetBarTires(function () {
                function repeatSelects1(facet_bar_label, selects, selectsIndex) {
                    return [selects(function () {
                            function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                            }
                            function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                                var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                                var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                                var isPopularField = popularEntries.length && unpopularEntries.length;
                                return [
                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                        'value': '',
                                        'key': 'null-option'
                                    }, '\n          ', this.title, '\n        ') : null,
                                    this.loading ? _createElement('option', {
                                        'key': 'loading-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    isPopularField ? [
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '11641'
                                        }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                        _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '11644'
                                        }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                    ] : null,
                                    _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                                ];
                            }
                            function scopeShowSelectedValues4() {
                                var showSelectedValues = this.selectedEntries.length;
                                return _createElement('option', {
                                    'key': '_current',
                                    'value': '_current'
                                }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                            }
                            function repeatEntry5(entry, index) {
                                return _createElement('div', {
                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                    'key': entry.value,
                                    'onClick': () => this.onChange(entry.term)
                                }, this.showCheckboxes ? _createElement('input', {
                                    'type': 'checkbox',
                                    'readOnly': true,
                                    'checked': entry.selected,
                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                    'key': '3488'
                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                    'className': 'cm_dropdown_hitCount',
                                    'key': '3690'
                                }, entry.hitCount) : null);
                            }
                            return _createElement('div', { 'className': 'cmTemplate_selectFacet' }, _createElement('div', { 'className': 'cm_vehicle-widget_select' }, [_createElement('div', {
                                    'className': this.extraClassName,
                                    'key': '980'
                                }, !this.isSearchable ? _createElement('div', {
                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                    'key': '149'
                                }, _createElement('select', {
                                    'className': 'cm_select_inner-select cm_select__pretty',
                                    'onChange': this.onChange,
                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                    'disabled': this.disabled,
                                    'aria-label': this.title
                                }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                            return _createElement('div', { 'className': 'cm_filterInput' });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                        'aria-label': this.title,
                                        'key': '2798'
                                    },
                                    !this.hideNullOption ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'onClick': () => this.onChange('')
                                    }, '\n      ', this.title, '\n    ') : null,
                                    this.loading ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                                ]) : null)]));
                        }, { count: undefined })];
                }
                function scopeFacet_bar_label2() {
                    var facet_bar_label = 'Shop by Size';
                    return _createElement('div', { 'className': 'cm_FacetBarTires' }, _createElement('div', { 'className': 'cm_FacetBarWheelsTires cm_facets' }, _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__facet-bar' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_title facettitle',
                        'onClick': this.collapseBar
                    }, '\n        ', facet_bar_label, '\n        ', this.isBarCollapsed ? [_createElement('svg', {
                            'className': 'icon accordion-indicator toggleLink-text toggleLink-text--off',
                            'key': '3620'
                        }, _createElement('use', { 'href': '#icon-add' }))] : null, !this.isBarCollapsed ? [_createElement('svg', {
                            'className': 'icon accordion-indicator toggleLink-text toggleLink-text--on',
                            'key': '5330'
                        }, _createElement('use', { 'href': '#icon-remove' }))] : null), _createElement('div', { 'className': 'cm_vehicle-widget_body cm_collapsible-elem facetbody' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container cmRepeater_facets cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this, facet_bar_label))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button button--secondary',
                        'onClick': this.discardFields,
                        'disabled': !this.hasSelection
                    }, '\n            Clear\n          ')))))));
                }
                return scopeFacet_bar_label2.apply(this, []);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.Facets(function () {
                return _createElement('div', { 'className': 'cm_Facets' }, [this.facets(function () {
                        function repeatFacets1(facets, facetsIndex) {
                            return [facets(function () {
                                    function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                        return [ShowAlwaysValues(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues2(Values, ValuesIndex) {
                                        return [Values(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues3(Values, ValuesIndex) {
                                        return [Values(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), !this.isCustomRange ? [
                                                    _createElement('a', {
                                                        'className': 'facetentrykey',
                                                        'tabIndex': '0',
                                                        'role': 'listitem',
                                                        'key': '1541'
                                                    }, _createElement('span', {}, this.value)),
                                                    _createElement('div', {
                                                        'className': 'facetentryval',
                                                        'key': '1543'
                                                    }, this.hitCount)
                                                ] : null, this.isCustomRange ? [_createElement('a', {
                                                        'className': 'facetentrykey',
                                                        'tabIndex': '0',
                                                        'role': 'listitem',
                                                        'key': '3901'
                                                    }, this.finite ? _createElement('span', { 'key': '503' }, this.range[0], ' - ', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '580' }, this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '650' }, this.range[1], ' and less') : null)] : null);
                                            }, { count: undefined })];
                                    }
                                    function scopeInchMm4() {
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', {
                                            'className': 'cmTemplate_sliderFacet',
                                            'key': '12459'
                                        }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min, inch, mm), _createElement('div', { 'className': 'max' }, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                                                return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                                            return _createElement('div', {
                                                                'placeholder': 'Min',
                                                                'className': 'cm_inputMin'
                                                            });
                                                        }, {
                                                            widgetName: 'undefined',
                                                            items: undefined
                                                        })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                                    'className': 'separator',
                                                    'key': '334'
                                                }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                                            return _createElement('div', {
                                                                'placeholder': 'Max',
                                                                'className': 'cm_inputMax'
                                                            });
                                                        }, {
                                                            widgetName: 'undefined',
                                                            items: undefined
                                                        })]] : null, _createElement('button', {
                                                    'type': 'button',
                                                    'className': 'cm_btn button button--secondary',
                                                    'onClick': this.setCustomRange
                                                }, '\n    GO\n  '));
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })]);
                                    }
                                    function repeatShowAlwaysValues5(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                        return [ShowAlwaysValues(function () {
                                                function scopeStarFill1(i, iIndex) {
                                                    var starFill = this.value - i;
                                                    return _createElement('span', {
                                                        'key': i,
                                                        'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                    });
                                                }
                                                function repeatI2(i, iIndex, starFill) {
                                                    return scopeStarFill1.apply(this, [
                                                        i,
                                                        iIndex
                                                    ]);
                                                }
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, _createElement.apply(this, [
                                                    'span',
                                                    { 'className': 'cm_review-stars' },
                                                    _map([
                                                        0,
                                                        1,
                                                        2,
                                                        3,
                                                        4
                                                    ], repeatI2.bind(this))
                                                ]), this.value !== '5' ? _createElement('span', {
                                                    'className': 'cm_and-up',
                                                    'key': '499'
                                                }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues6(Values, ValuesIndex) {
                                        return [Values(function () {
                                                function scopeStarFill1(i, iIndex) {
                                                    var starFill = this.value - i;
                                                    return _createElement('span', {
                                                        'key': i,
                                                        'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                    });
                                                }
                                                function repeatI2(i, iIndex, starFill) {
                                                    return scopeStarFill1.apply(this, [
                                                        i,
                                                        iIndex
                                                    ]);
                                                }
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, _createElement.apply(this, [
                                                    'span',
                                                    { 'className': 'cm_review-stars' },
                                                    _map([
                                                        0,
                                                        1,
                                                        2,
                                                        3,
                                                        4
                                                    ], repeatI2.bind(this))
                                                ]), this.value !== '5' ? _createElement('span', {
                                                    'className': 'cm_and-up',
                                                    'key': '499'
                                                }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, this.template !== 'toggleFacet' ? [
                                        _createElement('div', {
                                            'className': 'facettitle',
                                            'data-cm-role': 'toggle-facet',
                                            'tabIndex': '0',
                                            'key': '561'
                                        }, _createElement('span', {}, this.name), this.isCollapsed ? [_createElement('svg', {
                                                'className': 'icon accordion-indicator toggleLink-text toggleLink-text--off',
                                                'key': '2360'
                                            }, _createElement('use', { 'href': '#icon-add' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                                'className': 'icon accordion-indicator toggleLink-text toggleLink-text--on',
                                                'key': '3980'
                                            }, _createElement('use', { 'href': '#icon-remove' }))] : null),
                                        _createElement('div', {
                                            'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                            'key': '563'
                                        }, this.template === 'simpleFacet' ? _createElement('div', {
                                            'role': 'list',
                                            'className': 'cmTemplate_simpleFacet',
                                            'key': '757'
                                        }, this.showFilterInput ? _createElement('div', {
                                            'className': 'filter-input',
                                            'key': '855'
                                        }, [this.filterInput(function () {
                                                return _createElement('div', {
                                                    'className': 'input  cm_filterInput',
                                                    'placeholder': 'Enter'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], this.inputNotEmpty ? _createElement('span', {
                                            'className': 'filter-input_clear-container',
                                            'onClick': this.clearInput,
                                            'key': '1175'
                                        }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                                'className': 'facetdiv',
                                                'key': '13441'
                                            }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                            'div',
                                            { 'className': 'cmRepeater_ShowAlwaysValues' },
                                            _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                        ]), _createElement.apply(this, [
                                            'div',
                                            { 'className': 'cmRepeater_Values' },
                                            _map(this.Values, repeatValues2.bind(this))
                                        ]), this.needShowMore ? _createElement('div', {
                                            'className': 'facetdiv cm_show-all-container',
                                            'key': '3175'
                                        }, _createElement('a', {
                                            'className': 'cm_show-all',
                                            'data-cm-role': 'toggle-show-more',
                                            'tabIndex': '0'
                                        }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                                            'className': 'cmTemplate_selectFacet',
                                            'key': '3503'
                                        }, [this.select(function () {
                                                function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                                    return _createElement('option', {
                                                        'disabled': this.loading,
                                                        'value': entry.term,
                                                        'key': entry.value
                                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                                }
                                                function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                                    return _createElement('option', {
                                                        'disabled': this.loading,
                                                        'value': entry.term,
                                                        'key': entry.value
                                                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                                }
                                                function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                                                    var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                                                    var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                                                    var isPopularField = popularEntries.length && unpopularEntries.length;
                                                    return [
                                                        !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                            'value': '',
                                                            'key': 'null-option'
                                                        }, '\n          ', this.title, '\n        ') : null,
                                                        this.loading ? _createElement('option', {
                                                            'key': 'loading-option',
                                                            'disabled': true
                                                        }, '...loading...') : null,
                                                        isPopularField ? [
                                                            _createElement('option', {
                                                                'className': 'cm_option_title',
                                                                'disabled': true,
                                                                'key': '10771'
                                                            }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                                            _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                                            _createElement('option', {
                                                                'className': 'cm_option_title',
                                                                'disabled': true,
                                                                'key': '10774'
                                                            }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                                        ] : null,
                                                        _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                                                    ];
                                                }
                                                function scopeShowSelectedValues4() {
                                                    var showSelectedValues = this.selectedEntries.length;
                                                    return _createElement('option', {
                                                        'key': '_current',
                                                        'value': '_current'
                                                    }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                                                }
                                                function repeatEntry5(entry, index) {
                                                    return _createElement('div', {
                                                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                                        'key': entry.value,
                                                        'onClick': () => this.onChange(entry.term)
                                                    }, this.showCheckboxes ? _createElement('input', {
                                                        'type': 'checkbox',
                                                        'readOnly': true,
                                                        'checked': entry.selected,
                                                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                                        'key': '3401'
                                                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                                        'className': 'cm_dropdown_hitCount',
                                                        'key': '3603'
                                                    }, entry.hitCount) : null);
                                                }
                                                return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                                    'key': '62'
                                                }, _createElement('select', {
                                                    'className': 'cm_select_inner-select cm_select__pretty',
                                                    'onChange': this.onChange,
                                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                                    'disabled': this.disabled,
                                                    'aria-label': this.title
                                                }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                                            return _createElement('div', { 'className': 'cm_filterInput' });
                                                        }, {
                                                            widgetName: 'undefined',
                                                            items: undefined
                                                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                                    'div',
                                                    {
                                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                                        'aria-label': this.title,
                                                        'key': '2711'
                                                    },
                                                    !this.hideNullOption ? _createElement('div', {
                                                        'className': 'option',
                                                        'key': 'null-option',
                                                        'onClick': () => this.onChange('')
                                                    }, '\n      ', this.title, '\n    ') : null,
                                                    this.loading ? _createElement('div', {
                                                        'className': 'option',
                                                        'key': 'null-option',
                                                        'disabled': true
                                                    }, '...loading...') : null,
                                                    !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                                                ]) : null));
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                                            'role': 'list',
                                            'className': 'cmTemplate_priceFacet',
                                            'key': '9510'
                                        }, !this.ranges.length ? [
                                            _createElement('div', { 'key': '96061' }, this.selectedRawRange[0], ' to ', this.selectedRawRange[1]),
                                            '\n  ',
                                            this.slider,
                                            '\n  ',
                                            _createElement('div', {
                                                'className': 'cm_flex',
                                                'key': '96063'
                                            }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: ', this.rawMin), _createElement('div', {}, 'Max: ', this.rawMax))
                                        ] : null, this.ranges.length ? [_createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'cmRepeater_Values',
                                                    'key': '98701'
                                                },
                                                _map(this.Values, repeatValues3.bind(this))
                                            ])] : null, [this.Inputs(function () {
                                                return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Min price',
                                                            'className': 'cm_inputMin'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Max price',
                                                            'className': 'cm_inputMax'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })], _createElement('button', {
                                                    'type': 'button',
                                                    'className': 'cm_btn button button--secondary',
                                                    'onClick': this.setCustomRange
                                                }, '\n    GO\n  '));
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })]) : null, this.template === 'sliderFacet' ? scopeInchMm4.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                            'role': 'list',
                                            'className': 'cmTemplate_reviewFacet',
                                            'key': '14222'
                                        }, _createElement.apply(this, [
                                            'div',
                                            { 'className': 'cmRepeater_ShowAlwaysValues' },
                                            _map(this.ShowAlwaysValues, repeatShowAlwaysValues5.bind(this))
                                        ]), _createElement.apply(this, [
                                            'div',
                                            { 'className': 'cmRepeater_Values' },
                                            _map(this.Values, repeatValues6.bind(this))
                                        ])) : null)
                                    ] : null, this.template === 'toggleFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_toggleFacet',
                                        'key': '17382'
                                    }, _createElement('div', { 'className': 'facettitle cm_facet-toggle' }, _createElement('span', {}, this.name), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                                        'className': 'cm_facet-toggle_input',
                                        'type': 'checkbox',
                                        'checked': this.isToggled,
                                        'onClick': this.toggleFacet
                                    }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })))) : null);
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_facets' },
                            _map(this.facets, repeatFacets1.bind(this))
                        ]));
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]);
            }, {
                widgetName: 'undefined',
                items: undefined
            })])], _createElement('div', { 'className': 'cm_main-content' }, _createElement('div', { 'id': 'itemsBlock' }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '7050'
                }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '10350'
                }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1568'
            }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1982'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '2236'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2554'
            }, _createElement('div', {}, this.pageType !== 'search' && (!this.isFacetsSelected || this.notFilteredByVehicle) ? [
                '\n    This ',
                this.pageType,
                ' doesn\'t contain any\n    ',
                !!this.vehicleString && !this.notFilteredByVehicle ? [
                    '\n      products that fit a ',
                    _createElement('span', { 'key': '28171' }, '\'', this.vehicleString, '\'.')
                ] : null,
                !this.vehicleString || this.notFilteredByVehicle ? [' products.'] : null
            ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '30991' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString && !this.notFilteredByVehicle ? [
                '\n    Click ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '32301'
                }, 'here'),
                ' to clear\n    ',
                !this.vehicleString ? [' selection. '] : null,
                this.vehicleString && (!this.isFacetsSelected || !this.notFilteredByVehicle) ? ['\n      all keyword searches, filter selections, and vehicle selections to again browse all products.\n    '] : null
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3749'
            }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3968'
            }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '4212'
            }, 'Your search request failed to process\n', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '43630' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '44500' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '45330' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '43284' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.customMessage(function () {
            return _createElement('div', { 'className': 'cm_message cm_customMessage' }, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchVehicleWidget(function () {
            function repeatSelects1(selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                        }
                        function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                            var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                            var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                            var isPopularField = popularEntries.length && unpopularEntries.length;
                            return [
                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n          ', this.title, '\n        ') : null,
                                this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                isPopularField ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '10541'
                                    }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                    _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '10544'
                                    }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                ] : null,
                                _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                            ];
                        }
                        function scopeShowSelectedValues4() {
                            var showSelectedValues = this.selectedEntries.length;
                            return _createElement('option', {
                                'key': '_current',
                                'value': '_current'
                            }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                        }
                        function repeatEntry5(entry, index) {
                            return _createElement('div', {
                                'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '3378'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3580'
                            }, entry.hitCount) : null);
                        }
                        return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                            'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                            'key': '39'
                        }, _createElement('select', {
                            'className': 'cm_select_inner-select cm_select__pretty',
                            'onChange': this.onChange,
                            'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                            'disabled': this.disabled,
                            'aria-label': this.title
                        }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                    return _createElement('div', { 'className': 'cm_filterInput' });
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                'aria-label': this.title,
                                'key': '2688'
                            },
                            !this.hideNullOption ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'onClick': () => this.onChange('')
                            }, '\n      ', this.title, '\n    ') : null,
                            this.loading ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'disabled': true
                            }, '...loading...') : null,
                            !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                        ]) : null);
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__search cm_SearchVehicleWidget' }, this.template === 'active' ? _createElement('div', {
                'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                'key': '80'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.selectYourVehicleTitle || 'Select Your Vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                _map(this.selects, repeatSelects1.bind(this))
            ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button button--primary',
                'onClick': this.selectVehicle,
                'disabled': !this.allowToSetVehicle,
                'role': 'button',
                'tabIndex': this.allowToSetVehicle ? '0' : '-1'
            }, '\n    GO\n  '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button button--secondary',
                'onClick': this.discardVehicle,
                'disabled': !this.allowToDiscardVehicle,
                'role': 'button',
                'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
            }, '\n    Clear\n  ')))) : null, this.template === 'locked' ? _createElement('div', {
                'className': 'cm_vehicle-widget_label cmTemplate_locked',
                'key': '6856'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n  ', this.vehicleString, '\n  ', _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, this.fitmentSearchTitle), [this.facetToggle(function () {
                    return _createElement('div', { 'className': 'cm_facetToggle' }, _createElement('span', {
                        'className': 'cm_facet-toggle_label',
                        'onClick': this.toggleFacet
                    }, '\n  Show Universal Parts\n'), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                        'className': 'cm_facet-toggle_input',
                        'type': 'checkbox',
                        'checked': this.isToggled,
                        'onChange': this.toggleFacet
                    }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget__change button button--primary',
                'onClick': this.changeVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      Change\n    '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget__discard button button--secondary',
                'onClick': this.discardVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      Clear\n    ')))) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'button button--primary cm_dialogButton' }, '\n  Filters \n    ', this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '-5 0 394 394.00003',
                                    'key': '680'
                                }, _createElement('path', { 'd': 'm367.82 0h-351.26c-6.1992-0.011719-11.879 3.4492-14.711 8.9609-2.8711 5.5859-2.3672 12.312 1.3008 17.414l128.69 181.29c0.042968 0.0625 0.089843 0.12109 0.13281 0.18359 4.6758 6.3125 7.207 13.961 7.2188 21.816v147.8c-0.027344 4.375 1.6914 8.582 4.7734 11.688 3.0859 3.1016 7.2812 4.8516 11.656 4.8516 2.2227-0.003906 4.4258-0.44531 6.4805-1.3008l72.312-27.57c6.4766-1.9805 10.777-8.0938 10.777-15.453v-120.02c0.011719-7.8555 2.543-15.504 7.2148-21.816 0.042968-0.0625 0.089844-0.12109 0.13281-0.18359l128.69-181.29c3.668-5.0977 4.1719-11.82 1.3008-17.406-2.8281-5.5156-8.5117-8.9766-14.707-8.9648z' }))] : null, !this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '0 0 247.46 247.46',
                                    'key': '8090'
                                }, _createElement('path', { 'd': 'm246.74 13.984c-1.238-2.626-3.881-4.301-6.784-4.301h-232.46c-2.903 0-5.545 1.675-6.784 4.301-1.238 2.626-0.85 5.73 0.997 7.97l89.361 108.38v99.94c0 2.595 1.341 5.005 3.545 6.373 1.208 0.749 2.579 1.127 3.955 1.127 1.137 0 2.278-0.259 3.33-0.78l50.208-24.885c2.551-1.264 4.165-3.863 4.169-6.71l0.098-75.062 89.366-108.39c1.848-2.239 2.237-5.344 0.999-7.969zm-103.65 108.89c-1.105 1.34-1.711 3.023-1.713 4.761l-0.096 73.103-35.213 17.453v-90.546c0-1.741-0.605-3.428-1.713-4.771l-80.958-98.191h200.65l-80.958 98.191z' }))] : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', { 'className': 'cm_total-hits' }, _createElement('span', {}, this.totalHits, ' results')), _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', { 'className': 'sort-label' }, 'Sort by: '), '\n  ', this.sortSelect, '\n'), [_createElement('div', {
                    'className': 'category__view-toggle',
                    'key': '23560'
                }, _createElement('button', {
                    'onClick': this.setView('productList'),
                    'className': 'category__toggle-view js-category__toggle-view' + (this.view === 'productList' ? ' active-category-view' : ''),
                    'aria-label': 'Toggle Product List View',
                    'data-view-type': 'list'
                }, _createElement('span', { 'className': 'icon icon--list' }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-list' })))), _createElement('button', {
                    'onClick': this.setView('productGrid'),
                    'className': 'category__toggle-view js-category__toggle-view' + (this.view === 'productGrid' ? ' active-category-view' : ''),
                    'aria-label': 'Toggle Product Grid View',
                    'data-view-type': 'grid'
                }, _createElement('span', { 'className': 'icon icon--th' }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-th' })))))]);
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        return _createElement('li', { 'className': 'product' }, _createElement('article', {
                            'className': 'card' + (this.out_of_stock ? ' card--oos' : ''),
                            'data-event-type': 'grid',
                            'data-entity-id': this.id
                        }, _createElement('section', { 'className': 'card-summary' }, _createElement('figure', { 'className': 'card-figure' }, _createElement('a', {
                            'href': this.custom_url,
                            'className': 'card-figure__link',
                            'aria-label': this.name,
                            'data-event-type': 'product-click'
                        }, _createElement('div', { 'className': 'card-img-container' }, _createElement('img', {
                            'src': this.imageOrDefault(this.images_image_thumbnail1),
                            'alt': this.removeHTML(this.name),
                            'className': 'card-image lazyautosizes lazyloaded',
                            'onError': this.onImageError
                        }))), _createElement('figcaption', { 'className': 'card-figcaption' }, _createElement('div', { 'className': 'card-figcaption-body' }))), _createElement('div', { 'className': 'card-body' }, _createElement('p', mergeProps({
                            'className': 'card-text card-brand',
                            'data-test-info-type': 'brandName'
                        }, { dangerouslySetInnerHTML: { __html: this.brand_name } })), _createElement('h3', { 'className': 'card-title' }, _createElement('a', Object.assign({}, {
                            'aria-label': this.name,
                            'href': this.custom_url,
                            'data-event-type': 'product-click'
                        }, { dangerouslySetInnerHTML: { __html: this.name } }))), _createElement('div', { 'className': 'card-category' }, this.category ? _createElement('span', { 'key': '1150' }, 'Category: ', this.category[0]) : null), _createElement('div', {
                            'className': 'card-text',
                            'data-test-info-type': 'price'
                        }, this.retail_price > this.price ? _createElement('div', {
                            'className': 'price-section price-section--withoutTax rrp-price--withoutTax',
                            'key': '1299'
                        }, _createElement('span', {}, 'MSRP:'), _createElement('span', { 'className': 'price price--rrp' }, this.formatPrice(this.retail_price))) : null, this.on_sale ? _createElement('div', {
                            'className': 'price-section price-section--withoutTax non-sale-price--withoutTax',
                            'key': '1563'
                        }, _createElement('span', { 'className': 'price price--non-sale' }, this.formatPrice(this.regular_price))) : null, _createElement('div', { 'className': 'price-section price-section--withoutTax' }, _createElement('span', { 'className': 'price price--withoutTax' }, this.formatPrice(this.price)), _createElement('span', { 'className': 'price__each' }, 'each'))), _createElement('div', { 'className': 'card-atc' }, _createElement('div', { 'className': 'card-atc__section card-atc__section--qty' }, _createElement('label', {
                            'htmlFor': 'card-atc__qty-' + this.id,
                            'className': 'card-atc__label is-srOnly'
                        }, 'Quantity:'), _createElement('div', { 'className': 'card-atc-increment card-atc-increment--has-buttons' }, _createElement('button', {
                            'onClick': () => window.Convermax.handleProductQtySelector(this.id, false),
                            'className': 'button button--icon',
                            'data-action': 'dec',
                            'type': 'button'
                        }, _createElement('span', { 'className': 'is-srOnly' }, 'Decrease Quantity of undefined'), _createElement('i', {
                            'className': 'icon',
                            'aria-hidden': 'true'
                        }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-keyboard-arrow-down' })))), _createElement('input', {
                            'type': 'tel',
                            'className': 'form-input card-atc__input card-atc__input--total',
                            'name': 'card-atc__qty-' + this.id,
                            'id': 'card-atc__qty-' + this.id,
                            'defaultValue': '4',
                            'min': '1',
                            'pattern': '[0-9]*',
                            'aria-live': 'polite'
                        }), _createElement('button', {
                            'onClick': () => window.Convermax.handleProductQtySelector(this.id, true),
                            'className': 'button button--icon',
                            'data-action': 'inc',
                            'type': 'button'
                        }, _createElement('i', {
                            'className': 'icon',
                            'aria-hidden': 'true'
                        }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-keyboard-arrow-up' })))))), _createElement('div', { 'className': 'card-atc__section card-atc__section--action' }, !this.out_of_stock ? _createElement('a', {
                            'className': 'card-atc__button card-atc__button__in-stock button button--primary',
                            'href': '/cart.php?action=add&product_id=' + this.id + '&qty=4',
                            'data-product_id': this.id,
                            'key': '3434'
                        }, '\n              Add to Cart\n            ') : null, this.out_of_stock ? _createElement('a', {
                            'href': this.custom_url,
                            'data-event-type': 'product-click',
                            'className': 'card-atc__button button button--primary',
                            'data-product-id': this.id,
                            'key': '3686'
                        }, '\n              Out of stock\n            ') : null, _createElement('a', {
                            'href': this.custom_url,
                            'className': 'card-atc__button button button--primary card-view-tire__button'
                        }, '\n              View Tire\n            '))))), _createElement('div', { 'className': 'card-shipping' }, _createElement('span', { 'className': 'icon icon--truck' }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-truck' }))), '\n      Fast Free Shipping\n    ')));
                    }, { count: undefined })];
            }
            function repeatItems2(items, itemsIndex) {
                return [items(function () {
                        return _createElement('li', { 'className': 'product' }, _createElement('article', {
                            'className': 'listItem',
                            'data-event-type': 'list',
                            'data-entity-id': this.id
                        }, _createElement('figure', { 'className': 'listItem-figure' }, _createElement('a', {
                            'href': this.custom_url,
                            'className': 'listItem-figure__link',
                            'aria-label': this.removeHTML(this.name),
                            'data-event-type': 'product-click'
                        }, _createElement('img', {
                            'src': this.imageOrDefault(this.images_image_thumbnail1),
                            'alt': this.removeHTML(this.name),
                            'className': 'listItem-image lazyloaded',
                            'onError': this.onImageError
                        }))), _createElement('div', { 'className': 'listItem-body' }, _createElement('div', { 'className': 'listItem-content' }, _createElement('div', { 'className': 'listItem-content__info' }, _createElement('article', { 'className': 'listItem-content__info-top' }, _createElement('div', { 'className': 'listItem-heading' }, _createElement('h4', { 'className': 'listItem-title' }, _createElement('a', Object.assign({}, {
                            'href': this.custom_url,
                            'aria-label': this.removeHTML(this.name),
                            'data-event-type': 'product-click'
                        }, { dangerouslySetInnerHTML: { __html: this.name } }))))), _createElement('article', { 'className': 'listItem-content__info-bottom' }, _createElement('div', { 'className': 'listItem-details' }, this.category ? _createElement('span', { 'key': '1101' }, 'Category: ', this.category[0]) : null), _createElement('div', { 'className': 'listItem-shipping' }, _createElement('span', { 'className': 'icon icon--truck' }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-truck' }))), '\n              Fast Free Shipping\n            '))), _createElement('div', { 'className': 'listItem-actions' }, _createElement('div', { 'className': 'listItem-price' }, this.retail_price > this.price ? _createElement('div', {
                            'className': 'price-section price-section--withoutTax rrp-price--withoutTax',
                            'key': '1568'
                        }, _createElement('span', {}, 'MSRP:'), _createElement('span', { 'className': 'price price--rrp' }, this.formatPrice(this.retail_price))) : null, this.on_sale ? _createElement('div', {
                            'className': 'price-section price-section--withoutTax non-sale-price--withoutTax',
                            'key': '1840'
                        }, _createElement('span', { 'className': 'price price--non-sale' }, this.formatPrice(this.regular_price))) : null, _createElement('div', { 'className': 'price-section price-section--withoutTax' }, _createElement('span', { 'className': 'price price--withoutTax' }, this.formatPrice(this.price)), _createElement('span', { 'className': 'price__each' }, 'each'))), _createElement('div', { 'className': 'listItem-actions-buttons' }, !this.out_of_stock ? _createElement('a', {
                            'className': 'card-atc__button card-atc__button__in-stock button button--primary',
                            'href': '/cart.php?action=add&product_id=' + this.id + '&qty=4',
                            'key': '2362'
                        }, '\n              Add to Cart\n            ') : null, this.out_of_stock ? _createElement('a', {
                            'href': this.custom_url,
                            'data-event-type': 'product-click',
                            'className': 'card-atc__button button button--primary',
                            'data-product-id': this.id,
                            'key': '2586'
                        }, '\n              Out of stock\n            ') : null, _createElement('a', {
                            'href': this.custom_url,
                            'className': 'card-atc__button button button--primary card-view-tire__button'
                        }, '\n              View Tire\n            ')))))));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'page--category cm_SearchResult' }, this.view === 'productGrid' ? _createElement.apply(this, [
                'div',
                {
                    'className': 'productGrid cmRepeater_items',
                    'key': '49'
                },
                _map(this.items, repeatItems1.bind(this))
            ]) : null, this.view === 'productList' ? _createElement.apply(this, [
                'div',
                {
                    'className': 'productList cmRepeater_items',
                    'key': '7613'
                },
                _map(this.items, repeatItems2.bind(this))
            ]) : null, [this.message(function () {
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '7050'
                        }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '10350'
                        }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1568'
                    }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1982'
                    }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '2236'
                    }, 'Network error\n', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2554'
                    }, _createElement('div', {}, this.pageType !== 'search' && (!this.isFacetsSelected || this.notFilteredByVehicle) ? [
                        '\n    This ',
                        this.pageType,
                        ' doesn\'t contain any\n    ',
                        !!this.vehicleString && !this.notFilteredByVehicle ? [
                            '\n      products that fit a ',
                            _createElement('span', { 'key': '28171' }, '\'', this.vehicleString, '\'.')
                        ] : null,
                        !this.vehicleString || this.notFilteredByVehicle ? [' products.'] : null
                    ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '30991' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString && !this.notFilteredByVehicle ? [
                        '\n    Click ',
                        _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver,
                            'key': '32301'
                        }, 'here'),
                        ' to clear\n    ',
                        !this.vehicleString ? [' selection. '] : null,
                        this.vehicleString && (!this.isFacetsSelected || !this.notFilteredByVehicle) ? ['\n      all keyword searches, filter selections, and vehicle selections to again browse all products.\n    '] : null
                    ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '3749'
                    }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '3968'
                    }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '4212'
                    }, 'Your search request failed to process\n', this.error ? [
                        '\n  (',
                        this.error.status ? [
                            _createElement('b', { 'key': '43630' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '44500' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '45330' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '43284' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], [this.loadMore(function () {
                    return _createElement('div', { 'className': 'cm_loadMore' }, _createElement('div', { 'className': 'cm_load-more' }, this.isNotAllItemsReceived ? [
                        this.isActive ? [this.loading] : null,
                        !this.isActive ? [[this.loadMoreButton(function () {
                                    return _createElement('div', {
                                        'className': 'btn btn-default btn-inverse button button--primary cm_loadMoreButton',
                                        'onClick': this.activate
                                    }, '\n        ', window.Convermax.config?.loadMoreText || `Next ${ this.nextPageSize }`, '\n      ');
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null
                    ] : null));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })])));
}
        export const componentNames = ["cm:filterChips","cm:FilterChips","cm:filterInput","cm:FacetBarTires","cm:filterInput","cm:filterInput","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets","cm:Facets","cm:message","cm:customMessage","cm:filterInput","cm:facetToggle","cm:SearchVehicleWidget","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:message","cm:loadMoreButton","cm:loadMore","cm:SearchResult"]