const baseFitmentFields = ['Year', 'Make', 'Model', 'Submodel'];
const extraFitmentFields = [];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim', 'tire_height'];
const ignoreFields = [...tiresFields];

const brandSelectionPageUrl = '/brands/';
const categorySelectionPageUrl = '/all-categories/';

window.Convermax.handleProductQtySelector = (productId, isPlus) => {
  const qtyInput = window.document.querySelector(`#card-atc__qty-${productId}`);
  const addToCartButton = window.document.querySelector(
    `.card-atc__button__in-stock[data-product_id="${productId}"]`,
  );

  if (qtyInput && addToCartButton) {
    if (isPlus) {
      qtyInput.value++;
    } else if (qtyInput.value > 1) {
      qtyInput.value--;
    }

    addToCartButton.href = `/cart.php?action=add&product_id=${productId}&qty=${qtyInput.value}`;
  }
};

function getLocalPreselection(pageType, defaults) {
  const isCategory = defaults.termFromBreadcrumbs.startsWith('All Categories>');
  const isBrand = defaults.termFromBreadcrumbs.startsWith('Brands>');

  if (pageType === 'category' || (pageType === 'brand' && (isCategory || isBrand))) {
    const trimmedTermFromBreadcrumbs = defaults.termFromBreadcrumbs
      .replace('All Categories>', '')
      .replace('Brands>', '');

    return [
      {
        field: isBrand ? 'brand_name' : 'category',
        term: trimmedTermFromBreadcrumbs,
        treeLevel: isCategory ? trimmedTermFromBreadcrumbs.split('>').length - 1 : 0,
      },
    ];
  }

  return defaults.getter(pageType);
}

function InitFunc() {
  const unwantedPageWrappers = [
    ['.page .page-content--centered', 'page-content--centered'],
    ['body.page--brands .brandGrid', 'brandGrid'],
  ];

  unwantedPageWrappers.forEach(([elemSelector, className]) => {
    const wrapperElem = window.document.querySelector(elemSelector);
    wrapperElem?.classList.remove(className);
  });

  setTimeout(() => {
    const searchBoxButton = window.document.querySelector(
      '.navUser-action.navUser-action--quickSearch:not(.cm_search-box-root__mobile-container)',
    );

    if (searchBoxButton) {
      searchBoxButton.addEventListener('click', () => {
        window.document.querySelector('#cm_SearchBox .cm_searchInput')?.focus();
      });
    }
  }, 350);
}

export default {
  platform: 'bigcommerce',
  InitFunc,
  getLocalPreselection,
  defaultView: 'productGrid',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  page: {
    // prevent redirect to the search page
    getPageType: (defaults) =>
      window.document.querySelector(
        'body.page--category .page:not(.page-all-categories).page__subcategory-list',
      )
        ? 'category'
        : window.document.querySelector('.page.page-all-categories')
          ? 'categories'
          : window.document.querySelector('body.page--category .page .page-content .brand-listing')
            ? 'brand'
            : defaults.getter(),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    isVehicleSelectionIsolated: true,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: {
        replace: 'body.page--category .page:not(.page-all-categories).page__subcategory-list',
        class: 'container',
      },
      template: 'SearchPage',
    },
    {
      name: 'BrandPage',
      type: 'SearchPage',
      location: { selector: 'body.page--brand .page', class: 'container' },
      template: 'SearchPage',
    },
    {
      name: 'BrandListingPage',
      type: 'SearchPage',
      location: { selector: 'body.page--category .page .page-content .brand-listing', class: 'container' },
      template: 'SearchPage',
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select form-select--small',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'SearchBox',
      location: { replace: '.dropdown--quickSearch .form', class: 'cm_search-box-root-container' },
      template: 'SearchBox',
    },
    {
      name: 'MobileSearchBox',
      type: 'SearchBoxDialogButton',
      location: {
        insertBefore: '.navUser .navUser-action--quickSearch',
        class:
          'cm_search-box-root__mobile-container navUser-action navUser-action--quickSearch navUser-item--mag',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
      initCollapsed: true,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
      initCollapsed: true,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: 'body.page--brands #myList-old',
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: { selector: '.page.page-all-categories', class: 'page__top' },
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'HomeVehicleWidget__tab',
      type: 'VehicleWidget',
      location: '.hero-container .tire-finder .cm_vehicle-tab',
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'HomeTire__tab',
      type: 'RequestPanel',
      location: '.hero-container .tire-finder .cm_tire-tab',
      template: 'HomeTireBar',
      needInitRequest: true,
      selectFields: tiresFields,
    },
    {
      name: 'HeaderTire__tab',
      type: 'RequestPanel',
      location: '.header .tire-finder .cm_tire-tab',
      template: 'HomeTireBar',
      needInitRequest: true,
      selectFields: tiresFields,
    },
    {
      name: 'HeaderVehicleWidget__tab',
      type: 'VehicleWidget',
      location: '.header .tire-finder .cm_vehicle-tab',
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: {
        insertAfter: '.navUser-item.navUser-item--account',
        class: 'navUser-item navUser-item--garage cm_garage-container',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: {
        insertBefore: '.productView-details.product-options',
        class: 'cm_vehicle-widget__verify-fitment-container',
      },
      template: 'fitmentSearch/collapsibleVerifyFitment',
      isAlwaysColumnLayout: true,
      initCollapsed: true,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
