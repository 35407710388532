export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--secondary',

  FACET_TITLE_ICON: `
  <rt-virtual rt-if="this.isCollapsed" template="icons/facet-collapsed" />
  <rt-virtual rt-if="!this.isCollapsed" template="icons/facet-open" />`,

  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',
  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',

  GARAGE_SIZE: '<span class="garage-size countPill countPill--positive" key="garage-size">{{size}}</span>',

  CHANGE_VEHICLE: 'Change',
  DISCARD_VEHICLE: 'Clear',
};
